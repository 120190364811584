import { useCallback, useEffect, useMemo, useState } from "react";
import { GridFilterModel, GridSortModel } from "@mui/x-data-grid-pro";
import { LeaderRelationshipDenorm, RootState, ThunkDispatchType } from "../../store";
import { crm } from "../../store/cabinetApi/crm";
import { selectDenormalizedData } from "../../store/crm/selectors";
import CRMRelationships from "./CRMRelationships";
import { transformMuiDataGridFilterSortToApiModel } from "../../utils/dataUtils";
import { AppCrmLeaderRelationshipsListApiArg, Contact, Leader } from "../../store/cabinetApi/generated/crm";
import { useDispatch, useSelector } from "react-redux";
import { useCabNavigate, useMountEffect } from "../../utils/hooks";
import { fetchLeaders } from "../../store/leaders/actions";
import { PAGE_URL } from "../../constants";
import { createSearchParams } from "react-router-dom";
import { skipToken } from "@reduxjs/toolkit/query";


type RelationshipsApiFilter = Omit<AppCrmLeaderRelationshipsListApiArg, 'offset'|'limit'>;

const pageSize = 20;

const CRMRelationshipsContainer = () => {
  const [page, setPage] = useState(0);
  const [filter, setFilter] = useState<GridFilterModel>({ items: [] });
  const [sort, setSort] = useState<GridSortModel>([{ field: 'relationship_strength', sort: 'desc'}]);
  const [selectedLeader, setSelectedLeader] = useState<Leader['id'] | undefined>();

  const apiFilter: RelationshipsApiFilter = useMemo(() => {
    const modifiedSort = sort.map(s => s.field === 'prev_meeting_data' ? { ...s, field: 'prev_meeting_data_start'} : s);

    const apiModel = transformMuiDataGridFilterSortToApiModel<RelationshipsApiFilter>({
      filterModel: filter, sortModel: modifiedSort,
    }, true);
    return apiModel;
  }, [filter, sort]);

  const { data: relationshipData, isLoading: loadingRelationships } = crm.useAppCrmLeaderRelationshipsListQuery(
    selectedLeader != null
      ? { limit: pageSize, offset: page * pageSize, leader: selectedLeader,
        relationshipStrength: apiFilter.relationshipStrength, ...apiFilter
      }
      : skipToken
  );

  const relationships = relationshipData && selectDenormalizedData(relationshipData) as LeaderRelationshipDenorm[];

  const { data: contactCategories } = crm.useAppCrmContactCategoriesListQuery({ limit: 1000 });
  const { data: contactTypes } = crm.useAppCrmContactTypesListQuery({ limit: 1000 });

  const [createContact, { isLoading: contactIsCreating }] = crm.useAppCrmContactsCreateMutation();
  const [createRelationship, { isLoading: relationshipIsCreating }] = crm.useAppCrmLeaderRelationshipsCreateMutation();
  const [updateRelationship] = crm.useAppCrmLeaderRelationshipsPartialUpdateMutation();

  const leaders = useSelector((root: RootState) => root.leaders.leaders);
  const dispatch = useDispatch<ThunkDispatchType>();

  const firstLeaderId = leaders[0]?.id;

  useMountEffect(() => {
    dispatch(fetchLeaders());
  });

  useEffect(() => {
    if (firstLeaderId != null) {
      setSelectedLeader(firstLeaderId);
    }
  }, [firstLeaderId]);

  const handleUpdateSelectedLeader = (leaderId: Leader['id']) => {
    setPage(0);
    setSelectedLeader(leaderId);
  };

  const handleCreateContact = async (contact: Omit<Contact, 'id'>, relationshipLeaders?: Leader['id'][]) => {
    const c = await createContact({
      id: -1,
      ...contact,
    });
    if (relationshipLeaders && c.data && "id" in c.data) {
      const relationshipPromises = relationshipLeaders.map(rl => {
        return createRelationship({
          // need to fix schema so these fields aren't required
          id: -1, relationship_strength: '', relationship_strength_auto: '',
          contact: c.data.id, leader: rl
        });
      });
      await Promise.all(relationshipPromises);
    }
  };

  const handleUpdateRelationship = useCallback(async (
    relationship: Partial<LeaderRelationshipDenorm> & { id: LeaderRelationshipDenorm['id'] }
  ) => {
    const res = await updateRelationship({ id: relationship.id, patchedLeaderRelationship: {
      relationship_strength_override: relationship.relationship_strength_override,
    } });
    if ('data' in res) {
      return selectDenormalizedData(res.data) as LeaderRelationshipDenorm;
    }
  }, [updateRelationship]);


  const navigate = useCabNavigate();

  const handleNavigateToContact = (contactId: Contact['id']) => (
    navigate(`${PAGE_URL.CRM_PEOPLE}/${contactId}`)
  );

  const handleSetFilter = (fltr: GridFilterModel | undefined) => {
    setPage(0);
    setFilter(fltr || { items: [] });
  };

  const handleSetSort = (srt: GridSortModel | undefined) => {
    setPage(0);
    setSort(srt || []);
  };

  const handleSearch = (search: string) => {
    navigate({
      pathname: PAGE_URL.CRM_SEARCH,
      search: createSearchParams({ search }).toString(),
    });
  };

  return <CRMRelationships
    relationships={relationships || []}
    page={page}
    pageSize={pageSize}
    rowCount={relationshipData?.meta?.count || 0}
    onPageChange={setPage}
    filter={filter}
    onSetFilter={handleSetFilter}
    sort={sort}
    onSetSort={handleSetSort}
    loadingRelationships={loadingRelationships}
    leaderOptions={leaders}
    selectedLeaderId={selectedLeader}
    onLeaderSelected={handleUpdateSelectedLeader}
    onCreateContact={handleCreateContact}
    onNavigateToContact={handleNavigateToContact}
    contactCategories={contactCategories?.data || []}
    contactTypes={contactTypes?.data || []}
    isCreatingContact={contactIsCreating || relationshipIsCreating}
    onSearch={handleSearch}
    onUpdateRelationship={handleUpdateRelationship}
  />;
};

export default CRMRelationshipsContainer;
